import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.join');
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-join',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/page.join/view.scss */
/* src/app/page.join/view.scss: no such file or directory */`],
})
export class PageJoinComponent implements OnInit {
    constructor(@Inject( Service) public service: Service,@Inject( Router)  private router: Router) { }

    public communities: any[] = [];
    public groups: any[] = [];
    public filteredGroups: any[] = [];
    public view: string = 'step1';
    public errorMessages: any = {}; // 오류 메시지를 담는 객체
    public accountNameValid: string = '';  // 아이디 중복 확인 메시지
    public accountNameChecked: boolean = false;  // 중복 확인 여부 플래그


    public data: any = {
        account_name: '',
        first_name: '',
        last_name: '',
        email: '',
        institution: '',
        department: '',
        advisor: '',
        community_id: '',
        group_id: '',
        role: 'user',
        verified: false,
        verify: false,
        onetimepass: ''
    };

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(false, "/landing");
        await this.loadCommunities();
        await this.loadGroups();
    }

    public async checkAccountName() {
        this.errorMessages.account_name = '';
        const { code, data } = await wiz.call('check_account_name', { account_name: this.data.account_name });

        if (code === 200) {
            this.accountNameValid = "사용 가능한 아이디입니다.";
            this.accountNameChecked = true;  // 중복 확인 완료 플래그 설정
        } else if (code === 409) {
            this.errorMessages.account_name = "이미 사용 중인 아이디입니다.";
            this.accountNameChecked = false;  // 중복 확인 실패
        }

        await this.service.render();
    }

    public resetAccountNameCheck() {
        this.accountNameChecked = false;  // 사용자가 아이디를 수정할 경우 중복 확인 플래그 초기화
        this.accountNameValid = '';  // 성공 메시지 초기화
    }

    public async alert(message: string, status: string = 'error', cancel: any = false, action: string = '확인') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public async step(view: string) {
        this.view = view;
        await this.service.render();
    }

    public async loadCommunities() {
        const { code, data } = await wiz.call('get_communities');
        if (code === 200) {
            this.communities = data;
        }
    }

    public async loadGroups() {
        const { code, data } = await wiz.call('get_groups');
        if (code === 200) {
            this.groups = data;
        }
    }

    public filterGroupsByCommunity() {
        if (this.data.community_id) {
            // 커뮤니티 선택 시 그룹 목록을 필터링
            this.filteredGroups = this.groups.filter(group => group.service_id == this.data.community_id);

            // _admin과 _users로 끝나는 그룹 제외
            this.filteredGroups = this.filteredGroups.filter(group =>
                !group.name.includes('_admin') && !group.name.endsWith('_users')
            );

            // 커뮤니티가 선택되면 추가 그룹 선택을 초기화
            this.data.group_id = ''; // 그룹 선택 초기화
        } else {
            this.filteredGroups = []; // 커뮤니티 선택이 해제된 경우 그룹 목록 초기화
            this.data.group_id = ''; // 그룹 선택 초기화
        }

        // UI 업데이트
        this.service.render();
    }

    public async join_email_check() {
        let user = JSON.parse(JSON.stringify(this.data));
        let { code } = await wiz.call("emailCheck", user);

        if (code == 301) {
            this.data.verify = true;
            await this.emailAuthentication(this.data.email);
            await this.service.render();
            return;
        }

        await this.alert('사용할 수 없는 이메일입니다.');
    }

    public async emailAuthentication(email: string, showstatus: boolean = true) {
        await this.service.loading.show();
        let res = await wiz.call('emailAuthentication', { email });
        await this.service.loading.hide();
        if (!showstatus) return;
        if (res.code == 200) {
            await this.alert('이메일 인증 코드가 발송되었습니다', 'success');
        } else {
            await this.alert('이메일 인증 코드 발송 중 오류가 발생했습니다', 'error');
        }
    }

    public async emailVerify(email: string, onetimepass: string) {
        await this.service.loading.show();
        let res = await wiz.call('emailVerify', { email, onetimepass });
        await this.service.loading.hide();
        if (res.code == 200) {
            this.data.verified = true;
            await this.service.render();
        } else {
            await this.alert(res.data, 'error');
        }
    }
    public validateAccountName(account_name: string): boolean {
        const regex = /^[a-z0-9_]+$/; // 소문자, 숫자, 언더바 허용
        return regex.test(account_name);
    }

    public validateEmail(email: string): boolean {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // 이메일 형식
        return regex.test(email);
    }

    public async join() {
        this.errorMessages = {};  // 기존 오류 메시지 초기화

        // 필수 입력값 검사 및 유효성 검사
        if (!this.data.account_name) {
            this.errorMessages.account_name = '아이디를 입력해주세요.';
        } else if (!this.validateAccountName(this.data.account_name)) {
            this.errorMessages.account_name = '아이디는 소문자, 숫자, 언더바만 입력 가능합니다.';
        } else if (!this.accountNameChecked) {
            this.errorMessages.account_name = '아이디 중복 확인을 해주세요.'
        }

        if (!this.data.first_name) {
            this.errorMessages.first_name = '이름을 입력해주세요.';
        }

        if (!this.data.last_name) {
            this.errorMessages.last_name = '성을 입력해주세요.';
        }

        if (!this.data.email) {
            this.errorMessages.email = '이메일을 입력해주세요.';
        } else if (!this.validateEmail(this.data.email)) {
            this.errorMessages.email = '올바른 이메일 형식이 아닙니다.';
        }

        if (!this.data.community_id) {
            this.errorMessages.community_id = '커뮤니티를 선택해주세요.';
        }

        if (this.filteredGroups.length > 0 && !this.data.group_id) {
            this.errorMessages.group_id = '그룹을 선택해주세요.';
        }

        if (Object.keys(this.errorMessages).length > 0) {
            // 오류 메시지가 있을 경우 UI 업데이트 후 중단
            await this.service.render();
            return;
        }

        // 로딩 시작
        await this.service.loading.show();

        try {
            let account_name = this.data.account_name;

            // 비밀번호는 account_name + "1234" 형태로 생성
            let raw_password = account_name + "1234";

            // 비밀번호 해시 처리
            this.data.password = this.service.auth.hash(raw_password);

            let user = JSON.parse(JSON.stringify(this.data));
            user.full_name = `${user.first_name} ${user.last_name}`;


            const { code } = await wiz.call("join", user);
            if (code === 200) {
                this.service.alert.success(`${account_name} 님 GSDC 계정 생성을 위한 요청이 완료되었습니다.`);
                await this.router.navigate(['/login']);
            } else {
                this.service.alert.error("회원가입 요청에 실패했습니다.");
            }
        } catch (error) {
            console.error('회원가입 중 오류 발생:', error);
            this.service.alert.error("회원가입 처리 중 오류가 발생했습니다.");
        } finally {
            // 로딩 종료
            await this.service.loading.hide();
        }
    }

}

export default PageJoinComponent;