import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.mypage');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-mypage',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/page.mypage/view.scss */
/* src/app/page.mypage/view.scss: no such file or directory */`],
})
export class PageMypageComponent implements OnInit {
    public user: any = {
        account_name: '',
        full_name: '',
        institution: '',
        department: '',
        advisor: '',
        job_title: '',
        email: '',
        my_group: ''  // 그룹명
    };
    
    public qrCodeImage: string | null = null;
    public newPassword: string = '';
    public confirmPassword: string = '';
    public errorMessages: any = {};

    public activeTab: string = 'info';

    constructor(@Inject( Service) public service: Service) {}

    async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/login");
        await this.loadUserInfo();
    }

    public async alert(message: string, status: string = 'error') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: false,
            actionBtn: status,
            action: "확인",
            status: status,
        });
    }

        // 유효성 검사
    public validateInputs() {
        this.errorMessages = {};

        if (!this.newPassword) {
            this.errorMessages.newPassword = '새 비밀번호를 입력해주세요.';
            return false;
        }
        if (this.newPassword.length < 8) {
            this.errorMessages.newPassword = '비밀번호는 최소 8자 이상이어야 합니다.';
            return false;
        }
        if (!this.confirmPassword) {
            this.errorMessages.confirmPassword = '비밀번호 확인을 입력해주세요.';
            return false;
        }
        if (this.newPassword !== this.confirmPassword) {
            this.errorMessages.confirmPassword = '비밀번호가 일치하지 않습니다.';
            return false;
        }
        return true;
    }

    public async changePassword() {
        if (!this.validateInputs()) return;

        // 서버에 비밀번호 변경 요청
        this.service.loading.show();
        const { code, message } = await wiz.call("change_password", { newPassword: this.newPassword });
        this.service.loading.hide();

        if (code === 200) {
            await this.service.alert.success("비밀번호가 성공적으로 변경되었습니다.");
            this.setTab('info');
        } else {
            await this.service.alert.error(message);
        }
    }


    public setTab(tab: string) {
        this.activeTab = tab;
    }

    public async loadUserInfo() {
        this.service.loading.show();
        let { code, data } = await wiz.call("get_user_info");
        this.service.loading.hide();

        if (code === 200) {
            this.user = data.user;
        } else {
            this.service.alert.error("사용자 정보를 불러오는 데 실패했습니다.");
        }
    }

    public async saveUserInfo() {
        this.service.loading.show();
        let { code } = await wiz.call("update_user_info", this.user);
        this.service.loading.hide();

        if (code === 200) {
            this.service.alert.success("기본 정보가 성공적으로 업데이트되었습니다.");
        } else {
            this.service.alert.error("기본 정보 업데이트에 실패했습니다.");
        }
    }

    public async changePassword() {
        if (this.newPassword !== this.confirmPassword) {
            this.service.alert.error("새로운 패스워드가 일치하지 않습니다.");
            return;
        }

        this.service.loading.show();
        let { code } = await wiz.call("change_password", {
            oldPassword: this.oldPassword,
            newPassword: this.newPassword
        });
        this.service.loading.hide();

        if (code === 200) {
            this.service.alert.success("패스워드가 성공적으로 변경되었습니다.");
        } else {
            this.service.alert.error("패스워드 변경에 실패했습니다.");
        }
    }

    public async reissueOtp() {
        // 알림을 띄워서 OTP 재발급 여부를 확인
        // const confirm = await this.service.success("OTP를 재발급하시겠습니까?");
        // if (!confirm) return;

        this.service.loading.show();  // 로딩 시작

        let { code, data } = await wiz.call("reissue_otp");

        this.service.loading.hide();  // 로딩 종료

        if (code === 200) {
            this.qrCodeImage = data.qr_code_image;
            await this.alert("OTP가 성공적으로 재발급되었습니다.", "success");
        } else {
            await this.alert("OTP 재발급에 실패했습니다.", "error");
        }
    }
}

export default PageMypageComponent;