import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.findpw');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-findpw',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/page.findpw/view.scss */
/* src/app/page.findpw/view.scss: no such file or directory */`],
})
export class PageFindpwComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(false, "/landing");
    }

    public field: any = '';
    public sub_field: any = '';

    public data: any = {
        email: '',
        password: '',
        field: [],
        sub_field: [],
        extra: {}
    };

    public async alert(message: string, status: string = 'error', cancel: any = false, action: string = '확인') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public async join_email_check() {
        let user = JSON.parse(JSON.stringify(this.data));
        delete user.password;
        let { code } = await wiz.call("check", user);
        if (code == 200) {
            this.data.verify = true;
            await this.emailAuthentication(this.data.email);
            await this.service.render();
            return;
        }

        await this.alert('가입되지 않은 이메일 입니다.');
    }

    public async emailAuthentication(email: string, showstatus: boolean = true) {
        await this.service.loading.show();
        let res = await wiz.call('emailAuthentication', { email });
        await this.service.loading.hide();
        if (!showstatus) return;
        if (res.code == 200) {
            await this.alert('이메일 인증 코드가 발송되었습니다', 'success');
        } else {
            await this.alert('이메일 인증 코드 발송중 오류가 발생했습니다', 'error');
        }
    }

    public async emailVerify(email: string, onetimepass: string) {
        await this.service.loading.show();
        let res = await wiz.call('emailVerify', { email, onetimepass });
        await this.service.loading.hide();
        if (res.code == 200) {
            this.data.verified = true;
            await this.service.render();
        } else {
            await this.alert(res.data, 'error');
        }
    }

    public async join() {
        let password = this.data.password;
        let password_re = this.data.password_repeat;
        if (password.length < 8) return await this.alert("8글자 이상의 비밀번호를 설정해주세요");
        if (password.search(/[a-z]/i) < 0) return await this.alert("비밀번호에 알파벳을 포함해주세요");
        if (password.search(/[0-9]/) < 0) return await this.alert("비밀번호에 숫자를 포함해주세요");
        if (password != password_re) return await this.alert("비밀번호가 일치하지 않습니다");

        let user = JSON.parse(JSON.stringify(this.data));
        delete user.password_repeat;
        user.password = this.service.auth.hash(user.password);
        let { code } = await wiz.call("update", user);

        if (code == 200) {
            await this.alert("비밀번호가 변경되었습니다.", "success");
            this.service.href("/login");
        } else {
            await this.alert(data);
        }
    }

}

export default PageFindpwComponent;