import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.lpsysadmin.widget.company.product');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-lpsysadmin-widget-company-product',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.lpsysadmin.widget.company.product/view.scss */
.featured-image .featured-text {
  display: none;
}
.featured-image:hover .featured-text {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  text-align: center;
  font-size: 24px;
}
.featured-image:hover .featured-text i:hover {
  color: var(--wc-red);
}`],
})
export class PortalLpsysadminWidgetCompanyProductComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    @Input() company: any;

    public sortableOptions: any = {
        animation: 0,
        handle: '.handle'
    };

    public async ngOnInit() {
        await this.service.init();
    }

    public async update() {
        let body = JSON.parse(JSON.stringify(this.company));
        const { code, data } = await wiz.call("update", { info: JSON.stringify(body) });
        if (code == 200) await this.alert("저장되었습니다", "success");
        else if (code == 201) return this.service.href(`/admin/company/${data}/info`);
        else await this.alert("사업자 등록번호를 확인해주세요.", "error");
        await this.service.render();
    }

    public async clearValue(obj, key) {
        obj[key] = null;
        await this.service.render();
    }

    public async upload(item) {
        let files = await this.service.file.select();
        if (files.length == 0) return;

        await this.service.loading.show();

        let fd = new FormData();
        fd.append('upload', files[0]);
        let fdurl = '/file/upload';
        const { url } = await this.service.file.upload(fdurl, fd, async (percent: number, total: number, position: number) => { });

        item.featured_image = url;
        await this.service.loading.hide();
        this.service.render();
    }

    public async alert(message: string, status: string = 'error', cancel: any = false, action: string = '확인') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public timestamp() {
        return new Date().getTime();
    }

    public async addProduct() {
        this.company.extra.products.push({});
        await this.service.render();
    }

    public async deleteProduct(item) {
        this.company.extra.products.remove(item);
        this.service.render();
    }

    public async deleteProductImage(item) {
        item.featured_image = null;
        this.service.render();
    }
}

export default PortalLpsysadminWidgetCompanyProductComponent;