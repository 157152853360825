import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.gsdcsysadmin.request.list');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-gsdcsysadmin-request-list',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.gsdcsysadmin.request.list/view.scss */
/* src/app/portal.gsdcsysadmin.request.list/view.scss: no such file or directory */`],
})
export class PortalGsdcsysadminRequestListComponent implements OnInit {
    public requests: any[] = [];
    public loading: boolean = false;
    public role: string = '';  // 역할을 저장할 변수

    constructor(@Inject( Service) public service: Service) { }

    async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/login");

        // 세션에서 role 가져오기
        const session = await wiz.call("get_session");
        this.role = session.role;  // 역할 정보를 세션에서 가져옴

        await this.loadRequests();
    }

    async loadRequests() {
        this.loading = true;
        const { code, data } = await wiz.call('get_all_signup_requests');
        if (code === 200) {
            this.requests = data;
        }
        this.loading = false;
        await this.service.render();
    }

    // 승인 처리 함수
    async approveRequest(request_id: string) {
        const isConfirmed = confirm('승인하시겠습니까?'); // 승인 확인창
        if (!isConfirmed) {
            return; // 사용자가 취소한 경우 요청 중단
        }

        this.service.loading.show()
        const { code } = await wiz.call('approve_request', { request_id });
        this.service.loading.hide()

        if (code === 200) {
            await this.service.alert.success('요청이 승인되었습니다.');
            await this.loadRequests();  // 요청 목록 새로고침
        } else {
            await this.service.alert.error('승인 중 오류가 발생했습니다.');
        }
        await this.service.render();
    }

    // 거절 처리 함수
    async rejectRequest(request_id: string) {
        const isConfirmed = confirm('거절하시겠습니까?'); // 거절 확인창
        if (!isConfirmed) {
            return; // 사용자가 취소한 경우 요청 중단
        }

        this.loading = true;  // 로딩 시작
        const { code } = await wiz.call('reject_request', { request_id });
        this.loading = false;  // 로딩 종료

        if (code === 200) {
            await this.service.alert.success('요청이 거절되었습니다.');
            await this.loadRequests();  // 요청 목록 새로고침
        } else {
            await this.service.alert.error('거절 중 오류가 발생했습니다.');
        }
        await this.service.render();
    }
}

export default PortalGsdcsysadminRequestListComponent;