import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.lpsys.widget.pdfviewer');
import { OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import * as pdfjsLib from 'pdfjs-dist';
import { getDocument } from 'pdfjs-dist';

@Component({
    selector: 'wiz-portal-lpsys-widget-pdfviewer',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.lpsys.widget.pdfviewer/view.scss */
/* src/app/portal.lpsys.widget.pdfviewer/view.scss: no such file or directory */`],
})
export class PortalLpsysWidgetPdfviewerComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    @ViewChild('pdfContainer', { static: true }) pdfContainer: ElementRef<HTMLDivElement>;

    @Input() url: any;

    public async ngOnInit() {
        await this.service.init();
        await this.loadPDF();
    }

    public async loadPDF() {
        pdfjsLib.GlobalWorkerOptions.workerSrc = 'assets/pdfjs/pdf.worker.mjs';

        const url = this.url;
        const loadingTask = pdfjsLib.getDocument(url);
        const pdf = await loadingTask.promise;

        for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
            await this.renderPage(pdf, pageNumber);
        }
    }

    public async renderPage(pdf: any, pageNumber: number) {
        const page = await pdf.getPage(pageNumber);
        const scale = 1.5;
        const viewport = page.getViewport({ scale: scale });

        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        const renderContext = {
            canvasContext: context,
            viewport: viewport
        };

        await page.render(renderContext).promise;
        this.pdfContainer.nativeElement.appendChild(canvas);

        canvas.style.width = '100%';
        canvas.style.height = 'auto';
    }
}

export default PortalLpsysWidgetPdfviewerComponent;