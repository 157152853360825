import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.lpsys.widget.fund.item.info');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-lpsys-widget-fund-item-info',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.lpsys.widget.fund.item.info/view.scss */
/* src/app/portal.lpsys.widget.fund.item.info/view.scss: no such file or directory */`],
})
export class PortalLpsysWidgetFundItemInfoComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    @Input() fund: any;
    @Input() role: any;
    @Input() status: any;

    public isUserStatus: boolean = false;
    public visibility: any = {};

    public async ngOnInit() {
        await this.service.init();

        this.isUserStatus = false;
        try {
            if (this.status.status == '참여' || this.status.status == '대기') {
                this.isUserStatus = true;
            }
        } catch (e) {
        }

        this.visibility = this.fund.visibility;
        await this.service.render();
    }

    public displayMgmtRate(idx: number) {
        try {
            if (this.fund.mgmt_rate[idx] != 'null' && this.fund.mgmt_rate[idx]) {
                return this.fund.mgmt_rate[idx];
            }
        } catch (e) {
        }
        return '-';
    }

    public async update() {
        if (!this.status.applied_amount)
            return await this.alert('신청구좌를 입력해주세요');
        if (!this.status.extra.reason)
            return await this.alert('투자이유를 입력해주세요');
        if (!this.status.extra.agreement)
            return await this.alert('투자위험고지를 확인해주세요');

        let remain = this.fund.total_amount / this.fund.amount_per_share - this.fund.fundraising;
        if (this.status.applied_amount > remain) {
            if (!await this.service.alert.success(`현재 참여 가능 구좌를 초과하여 대기 상태로 신청됩니다. ['${this.fund.name}']에 대해 ${this.status.applied_amount}구좌를 참여하시겠습니까?`, '취소')) return;
            this.status.status = '대기';
        } else {
            if (!await this.service.alert.success(`['${this.fund.name}']에 대해 ${this.status.applied_amount}구좌를 참여하시겠습니까?`, '취소')) return;
        }

        const { code } = await wiz.call("update", {
            data: JSON.stringify(this.status)
        });

        if (code == 200) {
            return location.reload();
        }

        await this.alert('참여 신청에 실패했습니다. 남은 좌수를 확인해주세요.');
    }

    public validate() {
        if (this.status.applied_amount > this.fund.max_share_count)
            this.status.applied_amount = this.fund.max_share_count;
        if (this.status.applied_amount < this.fund.min_share_count)
            this.status.applied_amount = this.fund.min_share_count;
        this.service.render();
    }

    public async alert(message) {
        await this.service.alert.show({ title: "조합참여", message: message, action: "확인", actionBtn: "error", cancel: false, status: "error" });
    }

    public activeClass(target: string, status: string) {
        if (target == status) {
            return "mr-2 rounded-full bg-gray-600 px-3.5 py-2 text-sm font-semibold text-white shadow-sm cursor-default";
        }
        return "group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm font-semibold leading-6 text-gray-700 cursor-default";
    }

    public modal: any = {
        isshow: false,
        timestamp: 0,
        show: async () => {
            if (this.isUserStatus) return;
            this.modal.isshow = true;
            await this.service.render();
        },
        hide: async () => {
            this.modal.isshow = false;
            await this.service.render();
        },
        cancel: async () => {
            await this.modal.hide();
        },
        action: async () => {
            await this.update();
        },
        validate: async () => {
            let timestamp = new Date().getTime();
            this.modal.timestamp = timestamp;
            await this.service.render(300);
            if (timestamp < this.modal.timestamp) return;

            if (this.fund.max_share_count)
                if (this.status.applied_amount > this.fund.max_share_count)
                    this.status.applied_amount = this.fund.max_share_count;
            if (this.fund.min_share_count)
                if (this.status.applied_amount < this.fund.min_share_count)
                    this.status.applied_amount = this.fund.min_share_count;
            await this.service.render();
        }
    };

}

export default PortalLpsysWidgetFundItemInfoComponent;