import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.lpsys.widget.fund.item.investment');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-lpsys-widget-fund-item-investment',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.lpsys.widget.fund.item.investment/view.scss */
/* src/app/portal.lpsys.widget.fund.item.investment/view.scss: no such file or directory */`],
})
export class PortalLpsysWidgetFundItemInvestmentComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    @Input() fund: any;
    @Input() role: any;
    @Input() status: any;
    @Input() investment: any;

    public async ngOnInit() {
        await this.service.init();
    }

    public companyLink(company_number) {
        let stat: string = 'formed';
        if (this.fund.investment_status == '운용') stat = 'operating';
        if (this.fund.investment_status == '해산') stat = 'exit';
        return `/company/${stat}/${company_number}/info`;
    }
}

export default PortalLpsysWidgetFundItemInvestmentComponent;