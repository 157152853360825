import '@angular/compiler';
import { PortalGsdcsysadminTicketingItemComponent } from './portal.gsdcsysadmin.ticketing.item/portal.gsdcsysadmin.ticketing.item.component';
import { PortalSeasonPaginationComponent } from './portal.season.pagination/portal.season.pagination.component';
import { PageGroupIdSubComponent } from './page.group.id.sub/page.group.id.sub.component';
import { PortalDizestEditorDefaultComponent } from './portal.dizest.editor.default/portal.dizest.editor.default.component';
import { PortalDizestEditorWorkflowSidebarPipComponent } from './portal.dizest.editor.workflow.sidebar.pip/portal.dizest.editor.workflow.sidebar.pip.component';
import { PageMyprofileComponent } from './page.myprofile/page.myprofile.component';
import { PortalLpsysadminWidgetCompanyLogComponent } from './portal.lpsysadmin.widget.company.log/portal.lpsysadmin.widget.company.log.component';
import { PortalSeasonLoadingHexaComponent } from './portal.season.loading.hexa/portal.season.loading.hexa.component';
import { PortalDizestEditorWorkflowSidebarAiComponent } from './portal.dizest.editor.workflow.sidebar.ai/portal.dizest.editor.workflow.sidebar.ai.component';
import { PortalDizestEditorImageComponent } from './portal.dizest.editor.image/portal.dizest.editor.image.component';
import { PageResearchIdComponent } from './page.research.id/page.research.id.component';
import { PageOtpIssuanceComponent } from './page.otp.issuance/page.otp.issuance.component';
import { PortalGsdcsysuserWidgetResearchGraphComponent } from './portal.gsdcsysuser.widget.research.graph/portal.gsdcsysuser.widget.research.graph.component';
import { PortalGsdcsysadminResearchListComponent } from './portal.gsdcsysadmin.research.list/portal.gsdcsysadmin.research.list.component';
import { PortalDizestEditorHealthComponent } from './portal.dizest.editor.health/portal.dizest.editor.health.component';
import { PortalSeasonLoadingComponent } from './portal.season.loading/portal.season.loading.component';
import { PortalDizestEditorWorkflowSidebarInfoComponent } from './portal.dizest.editor.workflow.sidebar.info/portal.dizest.editor.workflow.sidebar.info.component';
import { PortalLpsysadminWidgetFundInviteComponent } from './portal.lpsysadmin.widget.fund.invite/portal.lpsysadmin.widget.fund.invite.component';
import { PortalGsdcsysadminWidgetCommunitySettingComponent } from './portal.gsdcsysadmin.widget.community.setting/portal.gsdcsysadmin.widget.community.setting.component';
import { PageJoinComponent } from './page.join/page.join.component';
import { PortalGsdcsysuserResearchListComponent } from './portal.gsdcsysuser.research.list/portal.gsdcsysuser.research.list.component';
import { PageFundItemComponent } from './page.fund.item/page.fund.item.component';
import { PortalLpsysFundItemComponent } from './portal.lpsys.fund.item/portal.lpsys.fund.item.component';
import { PortalDizestEditorTerminalComponent } from './portal.dizest.editor.terminal/portal.dizest.editor.terminal.component';
import { PortalGsdcsysuserTicketingListComponent } from './portal.gsdcsysuser.ticketing.list/portal.gsdcsysuser.ticketing.list.component';
import { PortalSeasonStatusbarComponent } from './portal.season.statusbar/portal.season.statusbar.component';
import { PortalGsdcsysadminWidgetUserProfileComponent } from './portal.gsdcsysadmin.widget.user.profile/portal.gsdcsysadmin.widget.user.profile.component';
import { PortalGsdcsysadminBoardListComponent } from './portal.gsdcsysadmin.board.list/portal.gsdcsysadmin.board.list.component';
import { PortalSeasonTabComponent } from './portal.season.tab/portal.season.tab.component';
import { PortalGsdcsysadminWidgetCommunityUserComponent } from './portal.gsdcsysadmin.widget.community.user/portal.gsdcsysadmin.widget.community.user.component';
import { PageCompanyItemComponent } from './page.company.item/page.company.item.component';
import { PortalLpsysMyprofilePasswordComponent } from './portal.lpsys.myprofile.password/portal.lpsys.myprofile.password.component';
import { PortalGsdcsysadminUserAddComponent } from './portal.gsdcsysadmin.user.add/portal.gsdcsysadmin.user.add.component';
import { PortalLpsysadminFundListFormedComponent } from './portal.lpsysadmin.fund.list.formed/portal.lpsysadmin.fund.list.formed.component';
import { PortalDizestEditorWorkflowSidebarAppsComponent } from './portal.dizest.editor.workflow.sidebar.apps/portal.dizest.editor.workflow.sidebar.apps.component';
import { PortalLpsysWidgetCompanyViewerComponent } from './portal.lpsys.widget.company.viewer/portal.lpsys.widget.company.viewer.component';
import { PortalLpsysadminFundListExitComponent } from './portal.lpsysadmin.fund.list.exit/portal.lpsysadmin.fund.list.exit.component';
import { PortalLpsysadminWidgetFundInfoComponent } from './portal.lpsysadmin.widget.fund.info/portal.lpsysadmin.widget.fund.info.component';
import { PortalDizestEditorWorkflowSidebarApiComponent } from './portal.dizest.editor.workflow.sidebar.api/portal.dizest.editor.workflow.sidebar.api.component';
import { PortalGsdcsysadminWidgetCommunityInfoEditComponent } from './portal.gsdcsysadmin.widget.community.info.edit/portal.gsdcsysadmin.widget.community.info.edit.component';
import { PortalGsdcsysuserWidgetGroupUserComponent } from './portal.gsdcsysuser.widget.group.user/portal.gsdcsysuser.widget.group.user.component';
import { PortalLpsysWidgetPdfviewerComponent } from './portal.lpsys.widget.pdfviewer/portal.lpsys.widget.pdfviewer.component';
import { PortalLpsysadminFundListOperationComponent } from './portal.lpsysadmin.fund.list.operation/portal.lpsysadmin.fund.list.operation.component';
import { PortalGsdcsysuserWidgetManualDriveComponent } from './portal.gsdcsysuser.widget.manual.drive/portal.gsdcsysuser.widget.manual.drive.component';
import { PortalSeasonUiDropdownComponent } from './portal.season.ui.dropdown/portal.season.ui.dropdown.component';
import { PortalLpsysadminFundItemComponent } from './portal.lpsysadmin.fund.item/portal.lpsysadmin.fund.item.component';
import { PortalGsdcsysadminBoardItemComponent } from './portal.gsdcsysadmin.board.item/portal.gsdcsysadmin.board.item.component';
import { PortalGsdcsysuserTicketingItemComponent } from './portal.gsdcsysuser.ticketing.item/portal.gsdcsysuser.ticketing.item.component';
import { PortalGsdcsysadminTicketingListComponent } from './portal.gsdcsysadmin.ticketing.list/portal.gsdcsysadmin.ticketing.list.component';
import { PortalLpsysWidgetFundItemInvestmentComponent } from './portal.lpsys.widget.fund.item.investment/portal.lpsys.widget.fund.item.investment.component';
import { PortalSeasonLoadingSeasonComponent } from './portal.season.loading.season/portal.season.loading.season.component';
import { PortalGsdcsysadminWidgetCommunityGroupSettingComponent } from './portal.gsdcsysadmin.widget.community.group.setting/portal.gsdcsysadmin.widget.community.group.setting.component';
import { PortalGsdcsysadminWidgetCommunityGroupUserComponent } from './portal.gsdcsysadmin.widget.community.group.user/portal.gsdcsysadmin.widget.community.group.user.component';
import { PortalLpsysadminUserListComponent } from './portal.lpsysadmin.user.list/portal.lpsysadmin.user.list.component';
import { PortalGsdcsysadminWidgetCommunityTicketingListComponent } from './portal.gsdcsysadmin.widget.community.ticketing.list/portal.gsdcsysadmin.widget.community.ticketing.list.component';
import { PortalGsdcsysadminManualListComponent } from './portal.gsdcsysadmin.manual.list/portal.gsdcsysadmin.manual.list.component';
import { PortalLpsysadminCompanyItemComponent } from './portal.lpsysadmin.company.item/portal.lpsysadmin.company.item.component';
import { PortalLpsysFundListOperatingComponent } from './portal.lpsys.fund.list.operating/portal.lpsys.fund.list.operating.component';
import { PortalLpsysFundListExitComponent } from './portal.lpsys.fund.list.exit/portal.lpsys.fund.list.exit.component';
import { PortalLpsysadminWidgetFundMemberComponent } from './portal.lpsysadmin.widget.fund.member/portal.lpsysadmin.widget.fund.member.component';
import { PortalGsdcsysadminWidgetCommunityGroupInfoComponent } from './portal.gsdcsysadmin.widget.community.group.info/portal.gsdcsysadmin.widget.community.group.info.component';
import { ComponentNavSidebarComponent } from './component.nav.sidebar/component.nav.sidebar.component';
import { PageLoginComponent } from './page.login/page.login.component';
import { PageBoardIdComponent } from './page.board.id/page.board.id.component';
import { PageSurveyComponent } from './page.survey/page.survey.component';
import { PortalGsdcsysuserWidgetGroupInfoComponent } from './portal.gsdcsysuser.widget.group.info/portal.gsdcsysuser.widget.group.info.component';
import { PortalLpsysCommunityBoardComponent } from './portal.lpsys.community.board/portal.lpsys.community.board.component';
import { PortalGsdcsysuserFaqListComponent } from './portal.gsdcsysuser.faq.list/portal.gsdcsysuser.faq.list.component';
import { PortalGsdcsysadminFaqItemComponent } from './portal.gsdcsysadmin.faq.item/portal.gsdcsysadmin.faq.item.component';
import { PortalGsdcsysadminWidgetCommunityBoardItemComponent } from './portal.gsdcsysadmin.widget.community.board.item/portal.gsdcsysadmin.widget.community.board.item.component';
import { PortalLpsysWidgetCompanyProductComponent } from './portal.lpsys.widget.company.product/portal.lpsys.widget.company.product.component';
import { LayoutEmptyComponent } from './layout.empty/layout.empty.component';
import { PortalDizestEditorWorkflowNodeComponent } from './portal.dizest.editor.workflow.node/portal.dizest.editor.workflow.node.component';
import { PortalLpsysMyprofileEditComponent } from './portal.lpsys.myprofile.edit/portal.lpsys.myprofile.edit.component';
import { PortalGsdcsysadminWidgetCommunityTicketingItemComponent } from './portal.gsdcsysadmin.widget.community.ticketing.item/portal.gsdcsysadmin.widget.community.ticketing.item.component';
import { PageUserComponent } from './page.user/page.user.component';
import { PageDashboardComponent } from './page.dashboard/page.dashboard.component';
import { PortalLpsysadminWidgetUserProfileComponent } from './portal.lpsysadmin.widget.user.profile/portal.lpsysadmin.widget.user.profile.component';
import { PortalGsdcsysadminResearchItemComponent } from './portal.gsdcsysadmin.research.item/portal.gsdcsysadmin.research.item.component';
import { PageResetPasswordComponent } from './page.reset.password/page.reset.password.component';
import { PortalLpsysadminWidgetCompanyInfoComponent } from './portal.lpsysadmin.widget.company.info/portal.lpsysadmin.widget.company.info.component';
import { PageSupportComponent } from './page.support/page.support.component';
import { PortalGsdcsysuserBoardListComponent } from './portal.gsdcsysuser.board.list/portal.gsdcsysuser.board.list.component';
import { PortalLpsysCompanyItemComponent } from './portal.lpsys.company.item/portal.lpsys.company.item.component';
import { PortalLpsysadminBoardListComponent } from './portal.lpsysadmin.board.list/portal.lpsysadmin.board.list.component';
import { ComponentDropdownComponent } from './component.dropdown/component.dropdown.component';
import { PortalGsdcsysadminWidgetCommunityBoardListComponent } from './portal.gsdcsysadmin.widget.community.board.list/portal.gsdcsysadmin.widget.community.board.list.component';
import { PortalGsdcsysadminUserListComponent } from './portal.gsdcsysadmin.user.list/portal.gsdcsysadmin.user.list.component';
import { PortalGsdcsysuserWidgetSurveyModalComponent } from './portal.gsdcsysuser.widget.survey.modal/portal.gsdcsysuser.widget.survey.modal.component';
import { PortalGsdcsysadminCommunityListComponent } from './portal.gsdcsysadmin.community.list/portal.gsdcsysadmin.community.list.component';
import { PageFindpwComponent } from './page.findpw/page.findpw.component';
import { PortalLpsysadminCompanyListComponent } from './portal.lpsysadmin.company.list/portal.lpsysadmin.company.list.component';
import { PortalGsdcsysuserBoardItemComponent } from './portal.gsdcsysuser.board.item/portal.gsdcsysuser.board.item.component';
import { PortalSeasonViewerTreeComponent } from './portal.season.viewer.tree/portal.season.viewer.tree.component';
import { PortalDizestEditorVideoComponent } from './portal.dizest.editor.video/portal.dizest.editor.video.component';
import { PortalDizestEditorSettingComponent } from './portal.dizest.editor.setting/portal.dizest.editor.setting.component';
import { PortalLpsysWidgetBoardComponent } from './portal.lpsys.widget.board/portal.lpsys.widget.board.component';
import { PortalGsdcsysadminCommunityItemComponent } from './portal.gsdcsysadmin.community.item/portal.gsdcsysadmin.community.item.component';
import { PortalLpsysadminBoardItemComponent } from './portal.lpsysadmin.board.item/portal.lpsysadmin.board.item.component';
import { PortalGsdcsysuserGroupUserComponent } from './portal.gsdcsysuser.group.user/portal.gsdcsysuser.group.user.component';
import { LayoutNavbarComponent } from './layout.navbar/layout.navbar.component';
import { PortalDizestDriveComponent } from './portal.dizest.drive/portal.dizest.drive.component';
import { PortalGsdcsysuserResearchItemComponent } from './portal.gsdcsysuser.research.item/portal.gsdcsysuser.research.item.component';
import { PortalLpsysadminWidgetFundMemberSellComponent } from './portal.lpsysadmin.widget.fund.member.sell/portal.lpsysadmin.widget.fund.member.sell.component';
import { PortalGsdcsysadminWidgetCommunityGroupListComponent } from './portal.gsdcsysadmin.widget.community.group.list/portal.gsdcsysadmin.widget.community.group.list.component';
import { PortalLpsysadminWidgetFundInvestmentComponent } from './portal.lpsysadmin.widget.fund.investment/portal.lpsysadmin.widget.fund.investment.component';
import { PageRequestComponent } from './page.request/page.request.component';
import { PortalGsdcsysuserGroupItemComponent } from './portal.gsdcsysuser.group.item/portal.gsdcsysuser.group.item.component';
import { PortalGsdcsysadminRequestListComponent } from './portal.gsdcsysadmin.request.list/portal.gsdcsysadmin.request.list.component';
import { PortalGsdcsysadminFaqListComponent } from './portal.gsdcsysadmin.faq.list/portal.gsdcsysadmin.faq.list.component';
import { PortalLpsysadminWidgetFundBoardComponent } from './portal.lpsysadmin.widget.fund.board/portal.lpsysadmin.widget.fund.board.component';
import { PortalGsdcsysuserManualListComponent } from './portal.gsdcsysuser.manual.list/portal.gsdcsysuser.manual.list.component';
import { LayoutSidebarComponent } from './layout.sidebar/layout.sidebar.component';
import { PageCommunityComponent } from './page.community/page.community.component';
import { PortalLpsysWidgetFundItemInfoComponent } from './portal.lpsys.widget.fund.item.info/portal.lpsys.widget.fund.item.info.component';
import { PortalGsdcsysuserSurveyItemComponent } from './portal.gsdcsysuser.survey.item/portal.gsdcsysuser.survey.item.component';
import { PortalLpsysadminWidgetCompanyBoardComponent } from './portal.lpsysadmin.widget.company.board/portal.lpsysadmin.widget.company.board.component';
import { PortalGsdcsysadminWidgetCommunityGroupItemComponent } from './portal.gsdcsysadmin.widget.community.group.item/portal.gsdcsysadmin.widget.community.group.item.component';
import { PortalLpsysadminWidgetFundDocumentComponent } from './portal.lpsysadmin.widget.fund.document/portal.lpsysadmin.widget.fund.document.component';
import { PortalDizestEditorWorkflowComponent } from './portal.dizest.editor.workflow/portal.dizest.editor.workflow.component';
import { PortalLpsysWidgetFundItemDocsComponent } from './portal.lpsys.widget.fund.item.docs/portal.lpsys.widget.fund.item.docs.component';
import { PortalLpsysMyprofileInfoComponent } from './portal.lpsys.myprofile.info/portal.lpsys.myprofile.info.component';
import { PageAdminComponent } from './page.admin/page.admin.component';
import { PortalSeasonAlertComponent } from './portal.season.alert/portal.season.alert.component';
import { PortalLpsysadminWidgetCompanyProductComponent } from './portal.lpsysadmin.widget.company.product/portal.lpsysadmin.widget.company.product.component';
import { PortalGsdcsysuserFaqItemComponent } from './portal.gsdcsysuser.faq.item/portal.gsdcsysuser.faq.item.component';
import { PortalGsdcsysuserGroupListComponent } from './portal.gsdcsysuser.group.list/portal.gsdcsysuser.group.list.component';
import { PortalLpsysadminWidgetCompanyEditorComponent } from './portal.lpsysadmin.widget.company.editor/portal.lpsysadmin.widget.company.editor.component';
import { PortalGsdcsysadminWidgetManualDriveComponent } from './portal.gsdcsysadmin.widget.manual.drive/portal.gsdcsysadmin.widget.manual.drive.component';
import { PortalLpsysFundListFormedComponent } from './portal.lpsys.fund.list.formed/portal.lpsys.fund.list.formed.component';
import { PortalLpsysCommunityViewComponent } from './portal.lpsys.community.view/portal.lpsys.community.view.component';
import { PortalGsdcsysuserRequestListComponent } from './portal.gsdcsysuser.request.list/portal.gsdcsysuser.request.list.component';
import { PageMypageComponent } from './page.mypage/page.mypage.component';
import { PortalDizestEditorWorkflowSidebarCodeComponent } from './portal.dizest.editor.workflow.sidebar.code/portal.dizest.editor.workflow.sidebar.code.component';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, COMPOSITION_BUFFER_MODE } from '@angular/forms';
import { NuMonacoEditorModule } from '@ng-util/monaco-editor';
import { SortablejsModule } from "src/libs/portal/season/ngx-sortablejs";
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';

@NgModule({
    declarations: [
        AppComponent,
        PortalGsdcsysadminTicketingItemComponent,
        PortalSeasonPaginationComponent,
        PageGroupIdSubComponent,
        PortalDizestEditorDefaultComponent,
        PortalDizestEditorWorkflowSidebarPipComponent,
        PageMyprofileComponent,
        PortalLpsysadminWidgetCompanyLogComponent,
        PortalSeasonLoadingHexaComponent,
        PortalDizestEditorWorkflowSidebarAiComponent,
        PortalDizestEditorImageComponent,
        PageResearchIdComponent,
        PageOtpIssuanceComponent,
        PortalGsdcsysuserWidgetResearchGraphComponent,
        PortalGsdcsysadminResearchListComponent,
        PortalDizestEditorHealthComponent,
        PortalSeasonLoadingComponent,
        PortalDizestEditorWorkflowSidebarInfoComponent,
        PortalLpsysadminWidgetFundInviteComponent,
        PortalGsdcsysadminWidgetCommunitySettingComponent,
        PageJoinComponent,
        PortalGsdcsysuserResearchListComponent,
        PageFundItemComponent,
        PortalLpsysFundItemComponent,
        PortalDizestEditorTerminalComponent,
        PortalGsdcsysuserTicketingListComponent,
        PortalSeasonStatusbarComponent,
        PortalGsdcsysadminWidgetUserProfileComponent,
        PortalGsdcsysadminBoardListComponent,
        PortalSeasonTabComponent,
        PortalGsdcsysadminWidgetCommunityUserComponent,
        PageCompanyItemComponent,
        PortalLpsysMyprofilePasswordComponent,
        PortalGsdcsysadminUserAddComponent,
        PortalLpsysadminFundListFormedComponent,
        PortalDizestEditorWorkflowSidebarAppsComponent,
        PortalLpsysWidgetCompanyViewerComponent,
        PortalLpsysadminFundListExitComponent,
        PortalLpsysadminWidgetFundInfoComponent,
        PortalDizestEditorWorkflowSidebarApiComponent,
        PortalGsdcsysadminWidgetCommunityInfoEditComponent,
        PortalGsdcsysuserWidgetGroupUserComponent,
        PortalLpsysWidgetPdfviewerComponent,
        PortalLpsysadminFundListOperationComponent,
        PortalGsdcsysuserWidgetManualDriveComponent,
        PortalSeasonUiDropdownComponent,
        PortalLpsysadminFundItemComponent,
        PortalGsdcsysadminBoardItemComponent,
        PortalGsdcsysuserTicketingItemComponent,
        PortalGsdcsysadminTicketingListComponent,
        PortalLpsysWidgetFundItemInvestmentComponent,
        PortalSeasonLoadingSeasonComponent,
        PortalGsdcsysadminWidgetCommunityGroupSettingComponent,
        PortalGsdcsysadminWidgetCommunityGroupUserComponent,
        PortalLpsysadminUserListComponent,
        PortalGsdcsysadminWidgetCommunityTicketingListComponent,
        PortalGsdcsysadminManualListComponent,
        PortalLpsysadminCompanyItemComponent,
        PortalLpsysFundListOperatingComponent,
        PortalLpsysFundListExitComponent,
        PortalLpsysadminWidgetFundMemberComponent,
        PortalGsdcsysadminWidgetCommunityGroupInfoComponent,
        ComponentNavSidebarComponent,
        PageLoginComponent,
        PageBoardIdComponent,
        PageSurveyComponent,
        PortalGsdcsysuserWidgetGroupInfoComponent,
        PortalLpsysCommunityBoardComponent,
        PortalGsdcsysuserFaqListComponent,
        PortalGsdcsysadminFaqItemComponent,
        PortalGsdcsysadminWidgetCommunityBoardItemComponent,
        PortalLpsysWidgetCompanyProductComponent,
        LayoutEmptyComponent,
        PortalDizestEditorWorkflowNodeComponent,
        PortalLpsysMyprofileEditComponent,
        PortalGsdcsysadminWidgetCommunityTicketingItemComponent,
        PageUserComponent,
        PageDashboardComponent,
        PortalLpsysadminWidgetUserProfileComponent,
        PortalGsdcsysadminResearchItemComponent,
        PageResetPasswordComponent,
        PortalLpsysadminWidgetCompanyInfoComponent,
        PageSupportComponent,
        PortalGsdcsysuserBoardListComponent,
        PortalLpsysCompanyItemComponent,
        PortalLpsysadminBoardListComponent,
        ComponentDropdownComponent,
        PortalGsdcsysadminWidgetCommunityBoardListComponent,
        PortalGsdcsysadminUserListComponent,
        PortalGsdcsysuserWidgetSurveyModalComponent,
        PortalGsdcsysadminCommunityListComponent,
        PageFindpwComponent,
        PortalLpsysadminCompanyListComponent,
        PortalGsdcsysuserBoardItemComponent,
        PortalSeasonViewerTreeComponent,
        PortalDizestEditorVideoComponent,
        PortalDizestEditorSettingComponent,
        PortalLpsysWidgetBoardComponent,
        PortalGsdcsysadminCommunityItemComponent,
        PortalLpsysadminBoardItemComponent,
        PortalGsdcsysuserGroupUserComponent,
        LayoutNavbarComponent,
        PortalDizestDriveComponent,
        PortalGsdcsysuserResearchItemComponent,
        PortalLpsysadminWidgetFundMemberSellComponent,
        PortalGsdcsysadminWidgetCommunityGroupListComponent,
        PortalLpsysadminWidgetFundInvestmentComponent,
        PageRequestComponent,
        PortalGsdcsysuserGroupItemComponent,
        PortalGsdcsysadminRequestListComponent,
        PortalGsdcsysadminFaqListComponent,
        PortalLpsysadminWidgetFundBoardComponent,
        PortalGsdcsysuserManualListComponent,
        LayoutSidebarComponent,
        PageCommunityComponent,
        PortalLpsysWidgetFundItemInfoComponent,
        PortalGsdcsysuserSurveyItemComponent,
        PortalLpsysadminWidgetCompanyBoardComponent,
        PortalGsdcsysadminWidgetCommunityGroupItemComponent,
        PortalLpsysadminWidgetFundDocumentComponent,
        PortalDizestEditorWorkflowComponent,
        PortalLpsysWidgetFundItemDocsComponent,
        PortalLpsysMyprofileInfoComponent,
        PageAdminComponent,
        PortalSeasonAlertComponent,
        PortalLpsysadminWidgetCompanyProductComponent,
        PortalGsdcsysuserFaqItemComponent,
        PortalGsdcsysuserGroupListComponent,
        PortalLpsysadminWidgetCompanyEditorComponent,
        PortalGsdcsysadminWidgetManualDriveComponent,
        PortalLpsysFundListFormedComponent,
        PortalLpsysCommunityViewComponent,
        PortalGsdcsysuserRequestListComponent,
        PageMypageComponent,
        PortalDizestEditorWorkflowSidebarCodeComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        SortablejsModule,
        KeyboardShortcutsModule.forRoot(),
        NuMonacoEditorModule.forRoot({ baseUrl: `lib` }),
        
    ],
    providers: [{
        provide: COMPOSITION_BUFFER_MODE,
        useValue: false
    }],
    bootstrap: [AppComponent]
})
export class AppModule { }