import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.lpsys.fund.list.exit');
import { OnInit } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-lpsys-fund-list-exit',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.lpsys.fund.list.exit/view.scss */
/* src/app/portal.lpsys.fund.list.exit/view.scss: no such file or directory */`],
})
export class PortalLpsysFundListExitComponent implements OnInit {

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.load();
    }

    public async load() {
        const { data } = await wiz.call("load");
        this.data = data;
        await this.service.render();
    }

    public iterItems(item: any) {
        if (item.investment.length == 0) {
            let row: any = JSON.parse(JSON.stringify(item));
            row.investment = {};
            row._rowspan = 1;
            return [row];
        }

        let rows: any = [];
        for (let i = 0; i < item.investment.length; i++) {
            let row: any = JSON.parse(JSON.stringify(item));
            if (i == 0) row._rowspan = item.investment.length;
            row.investment = item.investment[i];
            rows.push(row);
        }

        return rows;
    }

    public formatter: any = {
        date: (date) => {
            if (!date) return "-";
            return moment(date).format("YYYY-MM-DD");
        }
    }

    public sum(item) {
        if (!item.userdata.extra.sell) return 0;
        if (!item.userdata.extra.sell[item.investment.id]) return 0;
        let target = item.userdata.extra.sell[item.investment.id];
        let sum = 0;
        for (let i = 0; i < target.length; i++) {
            if (target[i].amount)
                sum += target[i].amount * 1;
        }
        return sum;
    }

}

export default PortalLpsysFundListExitComponent;