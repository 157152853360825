import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.lpsysadmin.company.item');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-lpsysadmin-company-item',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.lpsysadmin.company.item/view.scss */
/* src/app/portal.lpsysadmin.company.item/view.scss: no such file or directory */`],
})
export class PortalLpsysadminCompanyItemComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    @Input() company_id: any;
    @Input() menu: any;
    @Input() category: any = 'investment';

    public company: any = null;

    public async ngOnInit() {
        await this.service.init();
        await this.load();
    }

    public async load() {
        if (this.company_id == "new") {
            this.company = { extra: {}, isnew: true, status: this.category };
            await this.service.render();
            return;
        }
        const { data } = await wiz.call("info", { id: this.company_id });
        data.status = this.category;
        this.company = data;

        if (!this.company.id) this.company.registed_number = this.company_id;
        await this.service.render();
    }

    public async alert(message: string, status: string = 'error', cancel: any = false, action: string = '확인') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public linkCompanyMenu(menu: string) {
        return this.baseLink(`/${this.company_id}/${menu}`);
    }

    public isActive(link: string) {
        return location.pathname.indexOf(link) === 0
    }

    public activeClass(link: string) {
        if (this.isActive(link)) {
            return "group flex gap-x-3 rounded-md bg-gray-50 py-2 pl-2 pr-3 text-sm font-semibold leading-6 text-indigo-600";
        }
        return "group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600";
    }

    public baseLink(uri: string = '') {
        if (this.category == 'review') {
            return '/admin/review_company' + uri;
        }
        return '/admin/company' + uri;
    }
}

export default PortalLpsysadminCompanyItemComponent;