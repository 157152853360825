import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.lpsys.widget.board');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { ElementRef, ViewChild } from '@angular/core';
import ClassicEditor from "src/libs/portal/season/ckeditor/ckeditor";
import $ from "jquery";
import moment from "moment";
import showdown from 'showdown';

@Component({
    selector: 'wiz-portal-lpsys-widget-board',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.lpsys.widget.board/view.scss */
/* src/app/portal.lpsys.widget.board/view.scss: no such file or directory */`],
})
export class PortalLpsysWidgetBoardComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }
    @ViewChild('editor')
    public editorElement: ElementRef;
    public post_id: any;
    public comments: any;
    public vote: any;
    public vote_result: any;
    public nickname: any;
    public comment_text: any;

    @Input() target_id: any;
    @Input() category: any;
    @Input() board_type: any;

    public items: any = [];
    public loaded: boolean = false;
    public view: string = 'view';

    public option: any = {};

    public item: any = {};
    public editor: any;

    public search: any = {
        page: 1,
        text: "",
    };

    public pagination: any = {
        end: -1,
        start: -1,
    };

    public async request(uri, data = {}) {
        data.type = this.board_type;
        data.category = this.category;
        data.target_id = this.target_id;
        return await wiz.call(uri, data);
    }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/login");
        this.nickname = this.service.auth.session.name;
        await this.init();
        await this.load();
    }

    public async init() {
        let { data } = await this.request("option");
        this.option = data;
        await this.service.render();
    }

    public async load(page: number = 1) {
        this.search.page = page;
        this.loaded = false;
        this.items = [];
        await this.service.render();
        let body = JSON.parse(JSON.stringify(this.search));
        const { code, data } = await this.request("search", body);
        if (code != 200) return;
        let { rows, lastpage } = data;
        const startpage = Math.floor((page - 1) / 10) * 10 + 1;
        this.items = rows;
        this.pagination.start = startpage;
        this.pagination.end = lastpage;
        this.loaded = true;
        await this.service.render();
    }

    public async update() {
        this.item.content = this.editor.data.get();
        let data: any = JSON.stringify(this.item);
        await this.request("update", { data: data });
        await this.alert('저장되었습니다', 'Saved', 'success', '확인');

        if (!this.item.id) {
            await this.previous();
            await this.load(this.search.page);
        }
    }

    public async delete() {
        let res: any = await this.alert("정말로 삭제하시겠습니까?", "", "error", "삭제", "취소");
        if (!res) return;
        await this.request("delete", { id: this.item.id });
        await this.previous();
        await this.load(this.search.page);
    }

    public async upload() {
        let files = await this.service.file.select();
        if (files.length == 0) return;

        await this.service.loading.show();

        if (!this.item.extra.attachments) this.item.extra.attachments = [];

        for (let i = 0; i < files.length; i++) {
            let fd = new FormData();
            fd.append('upload', files[i]);
            let fdurl = '/file/upload';
            const { url } = await this.service.file.upload(fdurl, fd, async (percent: number, total: number, position: number) => { });
            this.item.extra.attachments.push({ name: files[i].name, url: url });
        }

        await this.service.loading.hide();
        this.service.render();
    }

    public async removeFile(file) {
        let res: any = await this.alert("정말로 삭제하시겠습니까?", "", "error", "삭제", "취소");
        if (!res) return;
        this.item.extra.attachments.remove(file);
        this.service.render();
    }

    public async alert(message: string, title: any = "", status: any = "error", action: string = '확인', cancel: any = false) {
        return await this.service.alert.show({
            title: title,
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public async select(item, view) {
        if (!view) this.view = 'view';
        if (item.id != 'new') {
            const { code, data } = await this.request("get", { id: item.id });
            if (code != 200) return await this.previous();
            item = data.item;
            this.vote = data.vote;
            this.vote_result = data.vote_result;
            if(!this.vote_result) this.vote_result = {};
            if(!this.vote_result.result) this.vote_result.result = {};
            this.comments = data.comments;
        }

        this.post_id = item.id;
        await this.service.render();

        if (this.viewType() == 'edit') {
            let element: any = this.editorElement.nativeElement;

            if ($(element).parent().find(".ck-content").length == 0) {
                let toolbar = 'heading | bold italic strikethrough underline | blockQuote code | bulletedList numberedList | outdent indent | uploadImage insertTable link codeBlock horizontalLine'.split(' ');

                this.editor = await ClassicEditor.create(element, {
                    toolbar: {
                        items: toolbar,
                        shouldNotGroupWhenFull: true
                    },
                    removePlugins: ["MediaEmbedToolbar"],
                    table: ClassicEditor.defaultConfig.table,
                    simpleUpload: {
                        uploadUrl: '/file/upload'
                    }
                });
            }

            if (item.id == 'new') {
                this.item = {
                    category: this.category,
                    fund_id: this.target_id,
                    title: '',
                    content: '',
                    level: '일반',
                    status: 'publish',
                    mode: '일반',
                    extra: {},
                    created: moment().format("YYYY-MM-DD")
                };
            } else {
                this.item = item;
                if (!this.item.level) item.level = '일반';
                this.item.created = moment(item.created).format("YYYY-MM-DD");
            }

            this.editor.data.set(this.item.content);
        } else {
            this.item = item;
            if (!this.item.level) item.level = '일반';
            this.item.created = moment(item.created).format("YYYY-MM-DD");
        }

        if (!this.item.extra.vote) this.item.extra.vote = [];

        await this.service.render();
    }

    public async previous() {
        this.post_id = null;
        this.vote = null;
        this.comments = null;
        this.item = {};
        await this.service.render();
    }

    public async actionVote() {
        if (!this.post_id) return;
        await this.request("vote", { board_id: this.post_id, vote: this.vote, nickname: this.nickname });
        await this.select(this.item);
        await this.service.alert.success("투표가 완료되었습니다");
    }

    public async sendComment() {
        if (!this.comment_text) return;
        await this.request("comment", { board_id: this.post_id, text: this.comment_text, nickname: this.nickname });
        this.comment_text = null;
        await this.select(this.item);
    }

    public async removeComment(comment) {
        let res = await this.service.alert.error("댓글을 삭제하시겠습니까?", "닫기", "삭제");
        if (!res) return;
        await this.request("remove_comment", comment);
        await this.select(this.item);
    }

    public async toggleView() {
        if (this.view == 'edit')
            this.view = 'view';
        else
            this.view = 'edit';
        await this.select(this.item, true);
        await this.service.render();
    }

    public viewType() {
        if (this.post_id) {
            if (this.option.use_write) {
                if (this.post_id == 'new') {
                    return 'edit';
                } else {
                    if (this.view == 'edit') {
                        return 'edit';
                    }
                }
            }
            return 'view';
        }
        return null;
    }

    public showdown(text) {
        if (!text) return "";
        let converter = new showdown.Converter();
        text = converter.makeHtml(text);
        return text;
    }
}

export default PortalLpsysWidgetBoardComponent;