import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.lpsys.widget.company.product');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import showdown from 'showdown';

@Component({
    selector: 'wiz-portal-lpsys-widget-company-product',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.lpsys.widget.company.product/view.scss */
/* src/app/portal.lpsys.widget.company.product/view.scss: no such file or directory */`],
})
export class PortalLpsysWidgetCompanyProductComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    @Input() company: any;

    public async ngOnInit() {
        await this.service.init();
    }

    public async alert(message: string, status: string = 'error', cancel: any = false, action: string = '확인') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public timestamp() {
        return new Date().getTime();
    }

    public showdown(text) {
        if (!text) return "";
        let converter = new showdown.Converter();
        text = converter.makeHtml(text);
        return text;
    }
}

export default PortalLpsysWidgetCompanyProductComponent;