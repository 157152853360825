import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.gsdcsysuser.research.item');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import moment from "moment";

@Component({
    selector: 'wiz-portal-gsdcsysuser-research-item',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.gsdcsysuser.research.item/view.scss */
/* src/app/portal.gsdcsysuser.research.item/view.scss: no such file or directory */`],
})
export class PortalGsdcsysuserResearchItemComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    @Input() research_id: any;
    @Input() category: any;

    public research: any = {};
    public url: any = '';

    public editTF: any = false;

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/login");
        await this.load();
        await this.service.loading.hide();
    }

    public async load() {
        if (this.research_id != 'new') {
            const { code, data } = await wiz.call("get", { id: this.research_id });
            if (code != 200) return;

            this.research = data;
            if (this.research.extra && this.research.extra != null) this.research.extra = JSON.parse(this.research.extra.replace(/'/g, '"'));
        } else {
            this.research = {
                title: '',
                extra: {},
                created: moment().format("YYYY-MM-DD")
            };
        }

        await this.service.render();

        this.research.created = moment(this.research.created).format("YYYY-MM-DD");
        this.research.updated = moment(this.research.updated).format("YYYY-MM-DD");

        await this.service.render();
    }

    public async edit() {
        if (!this.editTF) this.editTF = true;
        else {
            this.editTF = false;
            await this.load();
        }
        await this.service.render();
    }

    public async update() {
        if (this.research.title == "") return await this.service.alert.error("제목을 입력해주세요.")
        // if (this.research.posting_date == "") return await this.service.alert.error("게시 날짜를 입력해주세요.")
        this.research.extra = JSON.stringify(this.research.extra);
        await wiz.call("update", this.research);
        await this.service.alert.success('저장되었습니다');

        if (!this.research.id) {
            await this.service.href(`/research`);
        } else {
            this.editTF = false;
            await this.load();
        }
    }

    public async delete() {
        let res: any = await this.service.alert.error("정말로 삭제하시겠습니까?", "취소", "삭제");
        if (!res) return;
        await wiz.call("delete", { id: this.research.id });
        await this.service.href(`/research`);
    }

    public async upload(category: any) {
        let files = await this.service.file.select({ accept: '.pdf,.hwp' });
        if (files.length == 0) return;

        await this.service.loading.show();

        if (!this.research.extra || this.research.extra == "") this.research.extra = {};

        if (category == 'paper' && !this.research.extra.paper) this.research.extra.paper = [];
        else if (category == 'ack' && !this.research.extra.ack) this.research.extra.ack = [];

        for (let i = 0; i < files.length; i++) {
            let fd = new FormData();
            fd.append('upload', files[i]);
            let fdurl = '/file/upload';
            const { url } = await this.service.file.upload(fdurl, fd, async (percent: number, total: number, position: number) => { });
            if (category == 'paper') this.research.extra.paper.push({ name: files[i].name, url: url });
            else if (category == 'ack') this.research.extra.ack.push({ name: files[i].name, url: url });
        }

        await this.service.loading.hide();
        this.service.render();
    }

    public async removeFile(file, category) {
        let res: any = await this.service.alert.error("정말로 삭제하시겠습니까?", "취소", "삭제");
        if (!res) return;
        if (category == 'paper') this.research.extra.paper.remove(file);
        else if (category == 'ack') this.research.extra.ack.remove(file);
        this.service.render();
    }
}

export default PortalGsdcsysuserResearchItemComponent;