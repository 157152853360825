import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.lpsysadmin.widget.company.log');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { ElementRef, ViewChild } from '@angular/core';
import ClassicEditor from "src/libs/portal/season/ckeditor/ckeditor";
import showdown from 'showdown';

@Component({
    selector: 'wiz-portal-lpsysadmin-widget-company-log',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.lpsysadmin.widget.company.log/view.scss */
/* src/app/portal.lpsysadmin.widget.company.log/view.scss: no such file or directory */`],
})
export class PortalLpsysadminWidgetCompanyLogComponent implements OnInit {
    @ViewChild('editor')
    public editorElement: ElementRef;
    public editor: any;

    constructor(@Inject( Service) public service: Service) { }

    @Input() company: any;

    public list: any = [];
    public item: any = { extra: {} };

    public async ngOnInit() {
        await this.service.init();
        await this.load();

        let element: any = this.editorElement.nativeElement;
        let toolbar = 'heading | bold italic strikethrough underline | blockQuote code | bulletedList numberedList | outdent indent | uploadImage insertTable link codeBlock horizontalLine'.split(' ');

        this.editor = await ClassicEditor.create(element, {
            toolbar: {
                items: toolbar,
                shouldNotGroupWhenFull: true
            },
            removePlugins: ["MediaEmbedToolbar"],
            table: ClassicEditor.defaultConfig.table,
            simpleUpload: {
                uploadUrl: '/file/upload'
            }
        });
    }

    public async load() {
        const { code, data } = await wiz.call("search", { company_id: this.company.id });
        this.list = data;
        await this.service.render();
    }

    public async update() {
        this.item.content = this.editor.data.get();
        this.item.company_id = this.company.id;
        let data: any = JSON.stringify(this.item);
        await wiz.call("update", { data: data });
        await this.alert('저장되었습니다', 'success');
        this.item = { extra: {} };
        this.editor.data.set("");
        await this.load();
    }

    public async delete(log) {
        let res: any = await this.alert("정말로 삭제하시겠습니까?", "error", "취소", "삭제");
        if (!res) return;
        await wiz.call("delete", { id: log.id });
        await this.load();
    }


    public async upload() {
        let files = await this.service.file.select();
        if (files.length == 0) return;

        await this.service.loading.show();

        if (!this.item.extra.attachments) this.item.extra.attachments = [];

        for (let i = 0; i < files.length; i++) {
            let fd = new FormData();
            fd.append('upload', files[i]);
            let fdurl = '/file/upload';
            const { url } = await this.service.file.upload(fdurl, fd, async (percent: number, total: number, position: number) => { });
            this.item.extra.attachments.push({ name: files[i].name, url: url });
        }

        await this.service.loading.hide();
        this.service.render();
    }

    public async removeFile(file) {
        let res: any = await this.alert("정말로 삭제하시겠습니까?", "", "error", "삭제", "취소");
        if (!res) return;
        this.item.extra.attachments.remove(file);
        this.service.render();
    }

    public async alert(message: string, status: string = 'error', cancel: any = false, action: string = '확인') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public showdown(text) {
        if (!text) return "";
        let converter = new showdown.Converter();
        text = converter.makeHtml(text);
        return text;
    }
}

export default PortalLpsysadminWidgetCompanyLogComponent;