import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.company.item');
import { OnInit, DoCheck } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-company-item',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/page.company.item/view.scss */
/* src/app/page.company.item/view.scss: no such file or directory */`],
})
export class PageCompanyItemComponent implements OnInit, DoCheck {
    constructor(@Inject( Service) public service: Service) { }

    public main: string = '';
    public id: string = '';
    public menu: string = '';

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/login");

        this.main = WizRoute.segment.main;
        this.id = WizRoute.segment.id;
        this.menu = WizRoute.segment.menu;
        await this.service.render();
    }

    public async ngDoCheck() {
        this.main = WizRoute.segment.main;
        this.id = WizRoute.segment.id;
        this.menu = WizRoute.segment.menu;
    }

}

export default PageCompanyItemComponent;